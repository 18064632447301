/**
 * This contains the config of each of the environments where the app lives.
 */

import { FBProjects } from '@/firebase/projects';

const manualSelectEnvironment = null; // To manually select the environment, change null on this line to a key listed below
// const manualSelectEnvironment = 'qa'; //

export const environments = [
    {
        key: 'production',
        databases: {
            main: 'prod_students',
            notes: 'prod_notes',
            users: 'users',
            tags: 'prod_tags',
            calendar: 'calendar',
            events: 'events',
            analytics: 'analytics',
        },
        hosts: ['pst-prod-9000.web.app', 'pst-prod-9000.firebaseapp.com', 'pst.ctrise.org', 'apps.ctrise.org'],
        schoolCollection: 'prod_all_schools',
        project: 'pst-prod-9000',
        dummyData: false
    },
    {
        key: 'qa',
        databases: {
            main: 'dev_students',
            notes: 'dev_notes',
            users: 'users',
            tags: 'dev_tags',
            calendar: 'dev_calendar',
            events: 'dev_events',
            analytics: 'dev_analytics',
        },
        hosts: ['data-hub-qa.web.app', 'data-hub-qa.firebaseapp.com', 'qa.ctrise.org'],
        schoolCollection: 'dev_all_schools',
        project: 'pst-prod-9000',
        dummyData: false
    },
    {
        key: 'demo',
        databases: {
            main: 'demo_students',
            notes: 'demo_notes',
            users: 'users',
            tags: 'demo_tags',
            calendar: 'demo_calendar',
            events: 'demo_events',
            analytics: 'demo_analytics',
        },
        hosts: ['pst-demo-9000.web.app', 'pst-demo-9000.firebaseapp.com', 'demopst.ctrise.org', 'demoapps.ctrise.org'],
        schoolCollection: 'demo_all_schools',
        project: 'pst-prod-9000',
        dummyData: true
    },
    {
        key: 'development',
        databases: {
            main: 'web_dummy_students',
            notes: 'web_dummy_notes',
            users: 'users',
            tags: 'web_dummy_tags',
            calendar: 'dummy_calendar',
            events: 'dummy_events',
            analytics: 'dummy_analytics',
        },
        hosts: ['localhost', 'dev-dev-pst-final-2.web.app', 'dev-dev-pst-final-2.firebaseapp.com', 'alpha.ctrise.org'],
        schoolCollection: 'web_dummy_schools',
        project: 'dev-dev-pst-final-2',
        dummyData: true
    }
];

export const currentEnvironment = manualSelectEnvironment ?
    environments.find(e => e.key == manualSelectEnvironment):
    environments.find(e => e.hosts.includes(window.location.hostname));

if (!currentEnvironment) console.error('The current host is not recognized. Edit config.js to include the current path.');

let config = FBProjects.find(project => project.key == currentEnvironment.key).config;
if (window.location.hostname !== 'localhost') config.authDomain = window.location.hostname;
export const firebaseConfig = config;
